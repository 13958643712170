<template>
  <div class="invite-friend">
    <vue-qr :correctLevel="3" :autoColor="false" :text="shareUrl" :size="150" :margin="10" :logoSrc="logoUrl" :logoMargin="2"></vue-qr>
    <div class="text">
      <span>{{shareUrl}}</span>
    </div>
    <div class="flex btns">
      <div class="btn btn-save" @click="saveImg">
        {{$t('profile.invite.saveImg')}}
      </div>
      <div class="btn btn-copy" @click="copyLink">
        {{$t('profile.invite.saveUrl')}}
      </div>
    </div>
  </div>
</template>

<script>
import config from '../config';
import VueQr from 'vue-qr';
export default {
  name: "Invite",
  data() {
    return {
      shareUrl: config._BASE_URL+'register/'+this.$store.state.userinfo.address,
      logoUrl: '/statics/imgs/logo_icon.png'
    };
  },
  components:{
      VueQr
  },
  mounted() {
  },
  methods: {
    saveImg(){
      this.$message.info(this.$t('common.saveImgTips'));
    },
    copyLink(){
      let oInput = document.createElement('input');
      oInput.value = this.shareUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success(this.$t('common.copySuccess'));
      oInput.remove();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@light-green: #efca6b;
@dark-green: #f0d19a;
.invite-friend{
  .text{
    background: @dark-green;
    padding: 10px;
    word-break: break-all;
    margin: 10px auto;
    border-radius: 15px;
    color: #777;
  }
}
.btns{
  .btn{
    padding: 10px;
    border-radius: 15px;
    margin: 15px;
    flex: 1;
  }
  .btn-save {
    background: @light-green;
  }
  .btn-copy {
    background: @dark-green;
  }
}
</style>
