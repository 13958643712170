import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'hk',
    messages: {
        'hk': require('./lang/hk'),
        'en': require('./lang/en'),
        // 'my': require('./lang/my'),
        // 'vn': require('./lang/vn')
    }
});