<template>
  <div class="no-fundpass-box">
    <div class="pd-y-2 text color-white">{{$t('wallet.noFundpassText')}}</div>
    <div class="btn-primary-ghost" @click="goSetFundpass">
      {{$t('wallet.goSet')}}
    </div>
  </div>
</template>

<script>

export default {
  name: "NoFundpass",
  data() {
    return {
    };
  },
  components:{
  },
  mounted() {
  },
  methods: {
    goSetFundpass(){
      this.$store.commit('setBtmBox', false);
      this.$router.push({name:'security'});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

</style>
