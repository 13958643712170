export default {
    login: {
        method: 'post',
        url: '/login',
        hasToken: false
    },
    register: {
        method: 'post',
        url: '/register',
        hasToken: false
    },
    logout:{
        method: 'delete',
        url: '/logout',
        hasToken: true
    },
    userinfo: {
        method: 'post',
        url: '/userinfo',
        hasToken: true
    },
    teams: {
        method: 'post',
        url: '/teams',
        hasToken: true
    },
    clogs: {
        method: 'post',
        url: '/clogs',
        hasToken: true
    },
    priceLine: {
        method: 'post',
        url: '/priceLine',
        hasToken: true
    },
    price: {
        method: 'post',
        url: '/price',
        hasToken: true
    },
    gongxiu: {
        method: 'post',
        url: '/gongxiu',
        hasToken: true
    },
    gxtypes: {
        method: 'post',
        url: '/gxtypes',
        hasToken: true
    },
    gsils: {
        method: 'post',
        url: '/gsils',
        hasToken: true
    },
    gsilRerun: {
        method: 'post',
        url: '/gsil_rerun',
        hasToken: true
    },
    wallets: {
        method: 'post',
        url: '/wallets',
        hasToken: true
    },
    withdrawl: {
        method: 'post',
        url: '/withdrawl',
        hasToken: true
    },
    withdrawlSetting: {
        method: 'post',
        url: '/withdrawlSetting',
        hasToken: true
    },
    withdrawls: {
        method: 'post',
        url: '/withdrawls',
        hasToken: true
    },
    performance: {
        method: 'post',
        url: '/performance',
        hasToken: true
    },
    policy: {
        method: 'post',
        url: '/policy',
        hasToken: true
    },
    changePass: {
        method: 'post',
        url: '/changePassword',
        hasToken: true
    },
    fundPass: {
        method: 'post',
        url: '/fundPassword',
        hasToken: true
    },
    tinjiku: {
        method: 'post',
        url: '/tinjiku',
        hasToken: false
    },
    signinfo: {
        method: 'post',
        url: '/signinfo',
        hasToken: false
    },
    signinCount: {
        method: 'post',
        url: '/weekSigninCount',
        hasToken: true
    },
    signin: {
        method: 'post',
        url: '/signin',
        hasToken: true
    },
    dailyTask: {
        method: 'post',
        url: '/dailyTask',
        hasToken: false
    },
    userDailyTask: {
        method: 'post',
        url: '/userDailyTask',
        hasToken: true
    },
    doTask: {
        method: 'post',
        url: '/doTask',
        hasToken: true
    },
    grounds: {
        method: 'post',
        url: '/grounds',
        hasToken: true
    },
    uground: {
        method: 'post',
        url: '/ground',
        hasToken: true
    },
    ugrounds: {
        method: 'post',
        url: '/userGrounds',
        hasToken: true
    },
    sps: {
        method: 'post',
        url: '/sps',
        hasToken: true
    },
    plant:{
        method: 'post',
        url: '/plant',
        hasToken: true
    },
    userBudhitree:{
        method: 'post',
        url: '/userBudhitree',
        hasToken: true
    },
    waterTree:{
        method: 'post',
        url: '/waterTree',
        hasToken: true
    },
    harvest:{
        method: 'post',
        url: '/harvest',
        hasToken: true
    },
    stakeItems:{
        method: 'post',
        url: '/stakeItems',
        hasToken: true
    },
    stake: {
        method: 'post',
        url: '/stake',
        hasToken: true
    },
    stakes: {
        method: 'post',
        url: '/stakes',
        hasToken: true
    },
    withdrawStake: {
        method: 'post',
        url: '/withdrawStake',
        hasToken: true
    },
    ttransfer: {
        method: 'post',
        url: '/ttransfer',
        hasToken: true
    },
    transferSetting: {
        method: 'post',
        url: '/transferSetting',
        hasToken: true
    },
    payType: {
        method: 'post',
        url: '/payTypeSetting',
        hasToken: true
    },
    mixPay: {
        method: 'post',
        url: '/mixPay',
        hasToken: true
    },
    mrecords: {
        method: 'post',
        url: '/mrecords',
        hasToken: true
    },
}