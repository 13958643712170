import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request'
import { i18n } from './i18n'

import animated from "animate.css"
Vue.use(animated)
import vueInfiniteScroll from 'vue-infinite-scroll'
Vue.use(vueInfiniteScroll)

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
Vue.use(Antd)

import "./utils/px2rem"
import "./assets/reset.css"

Vue.prototype.$http = request;
Vue.config.productionTip = false


new Vue({
  router, store, i18n,
  render: h => h(App),
}).$mount('#app')
