<template>
  <div id="app">
    <div class="h100v" :class="isBlur">
      <router-view :key="$route.fullPath" />
    </div>
    <div v-if="$store.state.showLoading" class="loading-mask">
      <div class="mask"></div>
      <a-icon type="loading" :style="{color:'#fff',fontSize:'28px'}" />
    </div>
    <div v-if="$store.state.showMask" class="gMask" @click="closeMask"></div>
    <div v-if="$store.state.showBtmBox" class="btm-box animate__animated animate__slideInUp">
      <no-fundpass v-if="$store.state.showSetFundpassBox" />
      <Withdrawl v-if="$store.state.showWithdrawlBox" />
      <deposit-swap v-if="$store.state.showDepositSwapBox" />
      <Invite v-if="$store.state.showInviteBox" />
      <sel-lang v-if="$store.state.showLangBox"></sel-lang>
      <Ttransfer v-if="$store.state.showTtransferBox" />
    </div>
  </div>
</template>

<script>
import Withdrawl from './components/Withdrawl.vue';
import Invite from './components/Invite.vue';
import SelLang from './components/SelLang.vue';
import NoFundpass from './components/NoFundpass.vue';
import Ttransfer from './components/Ttransfer.vue';
import DepositSwap from './components/DepositSwap.vue';


export default {
  name: 'App',
  components: {
    Invite, SelLang, NoFundpass,
    Withdrawl,
    DepositSwap, Ttransfer
  },
  data(){
    return {
      
    }
  },
  computed:{
    isBlur(){
      return this.$store.state.showMask?'blur':'';
    },
  },
  created(){    
    this.$i18n.locale = this.$store.state.lang;
  },
  methods: {
    closeMask(){
      this.$store.commit('setBtmBox', false);
    },
  }
}
</script>

<style lang="less">
#app {
  font-family: Manrope,manroper,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: @base-font-size;
  height:100vh;
  overflow: hidden;
  background-image: url('~@/assets/imgs/bg.jpg');
  background-size: cover;
  color: #5a5a5a;
  .h100v{
    height: 100%;
  }
}
</style>
