<template>
  <div class="sel-lang">
    <div class="box flex" v-for="l,i in $t('langs')" :key="'lang'+i" @click="selLang(l.code)">
        <img :src="'/statics/imgs/langs/'+l.code+'.png'" alt="">
        <div class="title">
            {{l.title}}
        </div>
        <a-icon v-if="$store.state.lang==l.code" type="check-square" class="checked-icon" />
    </div>
  </div>
</template>

<script>

export default {
  name: "SelLang",
  data() {
    return {
    };
  },
  components:{
  },
  mounted() {
      // this.getData();
  },
  methods: {
    selLang(lang){
        this.$store.commit("setLang", lang);
        this.$i18n.locale = lang;
        this.$store.commit("setBtmBox", false);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@light-green: #efca6b;
@dark-green: #f0d19a;
.sel-lang{
  .box{
    background: @light-green;
    padding: 15px;
    word-break: break-all;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    margin-bottom: 10px;
    color: #777;
    .title{
        flex: 1;
        text-align: left;
        padding-left: 20px;
    }
    .checked-icon{
        color: #777;
        font-weight: bold;
    }
  }
}
</style>
