<template>
    <div class="withdrawl">
        <a-form-item>
            <span class="color-white" slot="label">{{$t('wallet.withdrawl.account')}}</span>
            <a-input class="bg-address" size="large" :value="address" disabled />
        </a-form-item>
        <div class="color-white rate">
          <span>1{{ $t('common.currency') }}&asymp;{{ bt_price }}USDT</span>
        </div>
        <a-form-item>
            <div class="color-white flex" slot="label">
              <span>{{$t('wallet.withdrawl.amount')}}</span>
              <span>
                {{$t('wallet.withdrawl.canuse')}} 
                <i>{{$store.state.userinfo.balances.bt/1000000}}{{$t('common.currency')}}</i>
              </span>
            </div>
            <div v-if="limit_on" >
              <a-input size="large" type="number" ref="amount" v-model="withdrawl_amount" :min="min_limit" :max="max_limit" :step="min_limit" :value="withdrawl_amount" @change="onIptChange" oninput="value=value.replace(/[^\d]/g, '')">
                <a-icon slot="prefix" type="dollar" />
                <span slot="suffix" class="color-red" v-if="errorMessage">{{ errorMessage }}</span>
                <span v-else slot="suffix" class="color-gray">
                  {{ withdrawlAmount }}{{ $t('common.currency') }}
                </span>
              </a-input>
            </div>
            
            <a-input v-else size="large" ref="amount" v-model="withdrawl_amount">
              <span slot="suffix" @click="withdrawl_amount=($store.state.userinfo.balances.bt/1000000)">MAX</span>
            </a-input>
        </a-form-item>
                
        <a-form-item>
            <div class="color-white flex" slot="label">
              <span>{{$t('wallet.withdrawl.password')}}</span>
              <span @click="forgetPass">
                {{$t('withdrawl.forget')}}
              </span>
            </div>
            <a-input-password size="large" ref="fund_password" v-model="fund_password" />
        </a-form-item>
        <div class="btn-primary-ghost mg30" @click="withdrawl">
            {{$t('common.submit')}}
        </div>
    </div>
</template>
<script>

export default {
  name: 'Withdrawl',
  data(){
    return {
      address: this.$store.state.userinfo.address,
      withdrawl_amount: 20,
      errorMessage: '',
      limit_on: true,
      min_limit: 10,
      max_limit: 1000,
      curIdx: 0,
      bt_price: 0.05,
      fund_password:''
    }
  },
  computed:{
    withdrawlAmount(){
      if(this.limit_on){
        return ((this.withdrawl_amount)/this.bt_price).toFixed(2);
      }else{
        return this.withdrawl_amount;
      }
    }
  },
  mounted(){
    this.getData();
    this.isWithdrawTime();
  },
  methods:{
    onIptChange(e){
      const {value} = e.target;
      if (value % 20 != 0) {
        this.errorMessage = this.$t('wallet.withdrawl.multipleTips',{'amount':this.min_limit});
        return true;
      }
      if(this.withdrawlAmount*1000000>this.$store.state.userinfo.balances.bt){
        this.errorMessage = this.$t('withdrawl.lackOfBalance');
        return true;
      }
      if(this.withdrawl_amount>this.max_limit){
        this.errorMessage = this.$t('wallet.withdrawl.lsMax',{amount:this.max_limit});
        return true;
      }
      this.errorMessage = '';
    },

    forgetPass(){
      this.$message.info(this.$t('withdrawl.forgetPassTips'));
    },
    withdrawl(){
      // console.log(this.$store.state.userinfo);
      if(this.errorMessage){
        return;
      }
      if(this.submitLoading){
        return;
      }
      if(this.withdrawl_amount < this.min_limit){
        this.errorMessage = this.$t('wallet.withdrawl.lgMin',{amount:this.min_limit});
        return;
      }
      if(this.withdrawl_amount > this.max_limit){
        this.errorMessage = this.$t('wallet.withdrawl.lsMax',{amount:this.max_limit});
        return;
      }
      if(!this.withdrawlAmount || !this.fund_password || this.withdrawlAmount<=0){
        this.$message.error(this.$t('withdrawl.cantbnull'));
        return;
      }
      if(!this.isWithdrawTime()){
        this.$message.error(this.$t('wallet.withdrawl.notWithdrawTime'));
        return;
      }
      let _balance = this.$store.state.userinfo.balances.bt;
      if(this.withdrawlAmount*1000000 > _balance){
          this.$message.error(this.$t('withdrawl.lackOfBalance'));
          return;
      }
      this.submitLoading = true;
      this.$http.call(this.$http.api.withdrawl,{
        data:{amount: this.withdrawlAmount,price: this.bt_price, password:this.fund_password}
      }).then(res=>{
        console.log(res);
        this.submitLoading = false;
        let resp = res.data;
        if(resp.code=='200'){
          this.$message.success(resp.data);
          this.$store.commit('setBtmBox', false);
          this.$store.commit('setMask', false);
          this.$router.go(0);
        }else{
          this.$message.error(resp.data);
        } 
      },res=>{
        console.log('something goes wrong...');
        console.log(res.response);
        this.submitLoading = false;
        if(res.response.status == '401'){
            this.$store.commit('setToken','');
            this.$router.push({name:'login'});
        }
      });
    },
    getData(){
      let _this = this
      this.$http.call(this.$http.api.withdrawlSetting).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.bt_price = Number(res.data.data.bt_price);
            _this.limit_on = res.data.data.limit_on;
            _this.min_limit = Number(res.data.data.min_limit);
            _this.max_limit = Number(res.data.data.max_limit);
            _this.w_start = res.data.data.w_start;
            _this.w_end = res.data.data.w_end;
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    isWithdrawTime(){
      const now = new Date();
      // 提取小时、分钟和秒
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      let _time = `${hours}:${minutes}`;
      return _time >= this.w_start && _time <= this.w_end;
    }
  }
}
</script>
<style lang="less" scoped>
.withdrawl{
  padding: 20px 15px;
  .label {
    color: #fff;
  }
  .bg-address {
    background: transparent;
    border: none;
    color: #fff;
  }
  .types{
    display: flex;
    justify-content: flex-end;
  }
  .rate {
    margin-top: -24px;
    margin-bottom: 24px;
  }
  .btn{
    padding: 3px 7px;
    border-radius: 0;
  }
  .items {
    display: flex;
    margin-top: -50px;
    margin-bottom: 50px;
    .item {
      padding: 7px;
      margin: 7px;
      background: #fff;
      color: @primary-color;
      flex: 1;
      font-weight: bold;
    }.on{
      background: @primary-color-shadow;
      color: #fff;
    }
  }
}

</style>
