<template>
    <div class="deposit-swap">
        <div class="flex periods">
          <div v-for="dt,di in $t('defi.defi.deposits')" :key="'dt'+di">
            <div>
              <span>{{periodRate[di]}}%</span>
              <div class="btn btn-primary" @click="periodChecked=di" :class="periodChecked==di?'':'btn-primary-sec'">
                {{dt.title}}
              </div>
            </div>
          </div>
          <div class=" mostProfits">
            {{$t('defi.defi.depositMostProfits',{profits: mostProfits})}}
          </div>
        </div>
        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.withdrawl.depositAmount')}}</span>
              <span class="">
                {{$t('wallet.withdrawl.canuse')}} {{$store.state.userinfo.balance/1000000}}{{$t('common.currency')}}
              </span>
            </div>
            <a-input size="large" ref="amount" v-model="deposit_amount">
              <span class="" slot="suffix" @click="deposit_amount=$store.state.userinfo.balance/1000000">MAX</span>
            </a-input>
        </a-form-item>

        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.withdrawl.password')}}</span>
              <span @click="forgetPass" class="">
                {{$t('withdrawl.forget')}}
              </span>
            </div>
            <a-input-password size="large" ref="fund_password" v-model="fund_password" />
        </a-form-item>
        <div class="btn-primary mg30" @click="depositSwap">
            {{$t('wallet.withdrawl.deposit')}}
        </div>
    </div>
</template>
<script>

export default {
  name: 'DepositSwap',
  data(){
    return {
      address: this.$store.state.userinfo.address,
      deposit_amount: 100,
      fund_password:'',
      periodChecked:1,
      periods: [30, 90, 180, 365],
      periodRate:[0.07,0.1,0.15,0.2]
    }
  },
  components:{
      
  },
  computed:{
    mostProfits(){
      return (this.deposit_amount * (1+(this.periodRate[this.periodChecked] / 100) * this.periods[this.periodChecked])).toFixed(2);
    }
  },
  methods:{
    forgetPass(){
      this.$message.info(this.$t('withdrawl.forgetPassTips'));

    },
    depositSwap(){
      if(this.submitLoading){
        return;
      }
      if(!this.deposit_amount || !this.fund_password){
        this.$message.error(this.$t('withdrawl.cantbnull'));
        return;
      }
      let _balance = this.$store.state.userinfo.balance;
      if(this.deposit_amount*1000000 > _balance){
          this.$message.error(this.$t('withdrawl.lackOfBalance'));
          return;
      }
      this.submitLoading = true;
      this.$http.call(this.$http.api.depositSwap,{
        data:{amount: this.deposit_amount*1000000, period: this.periodChecked, password:this.fund_password}
      }).then(res=>{
        console.log(res);
        this.submitLoading = false;
        let resp = res.data;
        if(resp.code=='200'){
          this.$message.success(resp.data);
          this.$store.commit('setBtmBox', false);
          this.$store.commit('setMask', false);
          this.$router.go(0);

        }else{
          this.$message.error(resp.data);
        } 
      },res=>{
        console.log('something goes wrong...');
        console.log(res.response);
        this.submitLoading = false;
        if(res.response.status == '401'){
            this.$store.commit('setToken','');
            this.$router.push({name:'login'});
        }
      }).then(()=>{
          
      });
    }
  }
}
</script>
<style lang="less">
.deposit-swap{
  padding: 20px 15px;
  color: #fff;
  .label {
    color: #fff;
  }
  .periods{
    margin-bottom: 30px;
    .btn{
      padding: 7px 18px;
    }
    .mostProfits{
      padding-top: 15px;
    }
    .btn-primary {
      font-weight: bold;
    }
    .btn-primary-sec {
      opacity: .7;
      color: #333;
      font-weight: normal;
    }
  }
}

</style>
