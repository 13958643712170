<template>
    <div class="ttransfer">
        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.transfers.receiver')}}</span>
            </div>
            <a-input size="large" ref="receiver" v-model="receive_address">
            </a-input>
        </a-form-item>
        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.transfers.amount')}}</span>
              <span class="color-white">
                {{$t('wallet.withdrawl.canuse')}} {{($store.state.userinfo.balances.bt/1000000)}} {{$t('common.currency')}}
              </span>
            </div>
            <a-input size="large" ref="amount" v-model="amount">
              <span slot="suffix" class="color-gray" v-if="fee>0">Fee: {{ (fee*amount/100).toFixed(2) }}</span>
            </a-input>
        </a-form-item>

        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.withdrawl.password')}}</span>
              <span @click="forgetPass" class="color-white">
                {{$t('withdrawl.forget')}}
              </span>
            </div>
            <a-input-password size="large" ref="fund_password" v-model="fund_password" />
        </a-form-item>
        <div class="btn-primary mg30" @click="ttransfer">
            {{$t('wallet.transfer')}}
        </div>
    </div>
</template>
<script>

export default {
  name: 'Ttransfer',
  data(){
    return {
      address: this.$store.state.userinfo.address,
      receive_address: '',
      amount: 100,
      fund_password:'',
      loading: false,
      fee: 0
    }
  },
  components:{
      
  },
  computed:{
    
  },
  mounted(){
    this.getData();
    this.$refs.receiver.focus();
  },
  methods:{
    forgetPass(){
      this.$message.info(this.$t('withdrawl.forgetPassTips'));

    },
    getData(){
      let _this = this
      this.$http.call(this.$http.api.transferSetting).then(function(res){
          console.log(res.data)
          if(res.data.code == '200'){
            _this.fee = Number(res.data.data);
          }
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    ttransfer(){
      if(this.loading){
        return;
      }
      this.loading = true;

      if(!this.amount || !this.fund_password){
        this.$message.error(this.$t('withdrawl.cantbnull'));
        return;
      }
      let _balance = this.$store.state.userinfo.balance;
      if(this.amount*1000000 > _balance){
          this.$message.error(this.$t('withdrawl.lackOfBalance'));
          return;
      }
      this.$http.call(this.$http.api.ttransfer,{
        data:{amount: this.amount, receive: this.receive_address, password:this.fund_password}
      }).then(res=>{
        console.log(res);
        this.loading = false;
        let resp = res.data;
        if(resp.code=='200'){
          this.$message.success(resp.data);
          this.$store.commit('setBtmBox', false);
          this.$store.commit('setMask', false);
          this.$router.go(0);

        }else{
          this.$message.error(resp.data);
        } 
      },res=>{
        console.log('something goes wrong...');
        console.log(res.response);
        this.loading = false;
        if(res.response.status == '401'){
            this.$store.commit('setToken','');
            this.$router.push({name:'login'});
        }
      }).then(()=>{
          
      });
    }
  }
}
</script>
<style lang="less">
.ttransfer{
  padding: 20px 15px;
  .label {
    color: @white;
  }
}

</style>
